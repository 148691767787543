import React from 'react';
import ContentWrap from '../../components/ContentWrap';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import FormItem from '../../components/Form/FormItem';
import Input from '../../components/Form/Input';
import * as styles from './index.module.less';
import Textarea from '../../components/Form/Input/Textarea';
import Radio from '../../components/Form/Radio';
import Button from '../../components/Button';
import UploadImage from '../../components/Form/UploadImage';

const QAList = [
  {
    label: '内容显示错误',
    value: 1,
  },
  {
    label: '分享链接失效',
    value: 2,
  },
  {
    label: '功能无法正常运行',
    value: 3,
  },
  {
    label: '交互逻辑复杂',
    value: 4,
  },
  {
    label: '其他',
    value: 5,
  },
];

export default function FeedbackPage() {
  return (
    <Layout>
      <ContentWrap>
        <form className={styles.form}>
          <h2>土星云对象存储问题反馈</h2>
          <hr />
          <br />
          <FormItem label="使用中遇到的问题" required>
            <Radio.Group>
              {QAList.map((item) => {
                return (
                  <Radio key={item.value} name="qa" required>
                    {item.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </FormItem>
          <FormItem label="问题描述或建议" required>
            <Textarea placeholder="请您详细描述所遇到的问题和对土星云的建议" required />
          </FormItem>
          <FormItem label="问题截图">
            <UploadImage />
          </FormItem>
          <FormItem label="联系方式">
            <Input placeholder="非必填，邮箱、手机号、微信、QQ 均可" />
          </FormItem>
          <br />
          <hr />
          <br />
          <div style={{ textAlign: 'center' }}>
            <div>感谢您提供的宝贵意见，以便我们能够进一步完善为您提供更贴心的云服务。</div>
            <br />
            <div>
              <Button type="primary" htmlType="submit">
                提 交
              </Button>
            </div>
          </div>
        </form>
      </ContentWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO title="问题反馈" />;
}
