import React from 'react';
import * as styles from './index.module.less';

export interface TextareaProps<T = any> extends React.TextareaHTMLAttributes<T> {}

export default function Textarea({ className, style, placeholder, ...inputProps }: InputProps) {
  return (
    <div className={[styles.input, className].join(' ')} style={style}>
      <div className={styles.before}></div>
      <textarea placeholder={placeholder} {...inputProps} />
      <div className={styles.after}></div>
    </div>
  );
}
