import React, { useState } from 'react';
import * as styles from './index.module.less';

interface GroupProps<T> {
  className?: string;
  style?: React.CSSProperties;
  // value?: T;
  // onChange?: (value: T) => void;
}
export default function Group<T>({
  className,
  style,
  children,
}: React.PropsWithChildren<GroupProps<T>>) {
  return (
    <div className={[className, styles.group].join(' ')} style={style}>
      {children}
    </div>
  );
}
