import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import * as styles from './index.module.less';

interface UploadImageProps {
  value?: string;
  onChange?: (value: string) => void;
}
export default function UploadImage({ value, onChange }: UploadImageProps) {
  return (
    <div className={styles.wrap}>
      <input type="file" />
      <PlusOutlined className={styles.icon}></PlusOutlined>
    </div>
  );
}
