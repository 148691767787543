import React from 'react';
import Group from '../Checkbox/Group';
import * as styles from '../Checkbox/index.module.less';

export interface RadioProps<T = any> extends React.InputHTMLAttributes<T> {}

export default function Radio({
  className,
  style,
  placeholder,
  children,
  ...radioProps
}: RadioProps) {
  return (
    <label className={[styles.checkbox, className].join(' ')} style={style}>
      <div className={styles.before}></div>
      <div className={styles.radioInput}>
        <input type="radio" placeholder={placeholder} {...radioProps} />
        <span className={styles.checkoutIcon}></span>
      </div>
      <div>{children}</div>
      <div className={styles.after}></div>
    </label>
  );
}

Radio.displayName = 'Radio';

Radio.Group = Group;
